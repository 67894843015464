import React, { useEffect } from 'react';
import { Box } from 'rebass/styled-components'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'

// TODO: Need some proper validation to detect users/restorers
function UserRoute({ user }) {
    // store isn't update before page load so need to fire dispatch after it changes.
    useEffect(() => {
        navigate(destination)
    }, [user.role])
    let destination = ''
    if (user.role) {
        if (user.role =='client') {
            destination = '/clients/welcome'
        } else {
            destination = '/restorers/welcome'
        }
    }


    return (<Box>

        {/* {console.log(user.role)} */}
    </Box>
    );
}

function mapStateToProps(state) {
    return {
        user: state.Auth.user,
    };
}

export default connect(mapStateToProps)(UserRoute);
